<template>
  <div class="Introduce">
    <div class="name">
        项目介绍
    </div>
    <div class="collapse">
        <p>
          “中国知链”（http://www.cnpkp.com）由武汉大学和教育部教育信息管理中心技术团队开发。中国知链学术论文知识产权保护及认证可信体系通过区块链技术，基于IPFS协议，token机制，区块链全网AI智能检索监控存证，AI知识图谱搜索引擎，去中心化内容溯源和筛选机制实现内容的存储、检索、版权保护和知识传播，打造web3.0知识服务平台。

        </p>
        <p>
          中国知链以“研发数字作品版权技术、服务数字作品版权产业、发展版权数字经济”为使命，打造知识产权交易、文创交易、媒资交易、网游资产交易、视频版权交易五个平台，实现知识产权确权、保护、成果转化、产权质押等数字文化产业部署及升级。

        </p>
        <a-row class="collapse_ul">
          <a-col :md="12" :lg="6" :xl="6"><img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Introduce01.png" alt=""></a-col>
          <a-col :md="12" :lg="6" :xl="6"><img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Introduce02.png" alt=""></a-col>
          <a-col :md="12" :lg="6" :xl="6"><img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Introduce03.png" alt=""></a-col>
          <a-col :md="12" :lg="6" :xl="6"><img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Introduce04.png" alt=""></a-col>
        </a-row>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'Introduce',
 components:{},
  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.collapse{
  padding: 20px 0;
  p{
  color: rgba(0, 18, 22, 0.65);
  text-indent: 2em;
  padding: 10px 0;
  line-height:1.769230769230769;
  }
  .collapse_ul{
    img{
      width: 100%;
    }
  }
}
.Introduce {
    padding: 0 32px 0;
}
.name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,18,22,.85);
    line-height: 36px;
    padding: 32px 0 32px 0;
    border-bottom: 1px solid #ebeef5;
}
@media screen and (min-width: 1px) and (max-width: 767px)  {
  .collapse_ul {
      .ant-col{width: 50%;float: left;}
  }
}
</style>
